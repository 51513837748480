/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/react'
import { map, get, size } from 'lodash'
import { default_theme as theme } from '../emotion/theme'
import { Trans, Translation } from 'react-i18next'
import { Separator } from './layout/Separator'
import CurrencyValue from './CurrencyValue'
import {
  Row,
  Col,
  Container,
  Popover,
  OverlayTrigger,
  Button,
} from 'react-bootstrap'
import { CheckboxButton } from './layout/CheckboxButton'
import { InlineIcon } from './layout/InlineIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { HtmlText } from './layout/HtmlText'

export const ProductCard = ({ product, is_selected, onSelect, billing_frequency }) => {

    const setup_price_excluding_vat_euros = billing_frequency === 'monthly' ?
                                            get(product, "setup_price_for_monthly_subscriptions_excluding_vat_euros") :
                                            get(product, "setup_price_for_annual_subscriptions_excluding_vat_euros")
    
  return (
    <div
      css={[
        card_container_style,
        is_selected ? card_container_selected_style : null,
      ]}
    >
      <div css={product_clickable_style} onClick={onSelect}>
        <div css={header_style}>{product.name}</div>
        <div css={price_style}>
          <div css={price_line_style}>
            {size(product.supplement_number_types) > 0 && (
                <>
                  <Trans i18nKey="from_vanaf">From</Trans>
                &nbsp;
                </>
            )}

            { billing_frequency == 'monthly' && 
              <CurrencyValue
                  value={product.monthly_subscription_price_excluding_vat_euros}
                  variant="multiheight"
                  align_center={true}
              />
            }
            { billing_frequency == 'annually' && 
              <CurrencyValue
                  value={product.monthly_equivalent_annual_subscription_price_excluding_vat_euros}
                  variant="multiheight"
                  align_center={true}
              />
            }
          </div>

          <div css={price_sub_style}>
            {setup_price_excluding_vat_euros == 0 && (
                <Trans>per month</Trans>
            )}
            {setup_price_excluding_vat_euros > 0 && (
                <>
                  <Trans>per month</Trans> +{' '}
                  <CurrencyValue
                      value={setup_price_excluding_vat_euros}
                      enable_inline_spacing={true}
                  />{' '}
                  <Trans>setup costs</Trans>
                </>
            )}
          </div>

            <div css={annual_equivalent_price_style}>
              { billing_frequency == 'annually' && product.annual_subscription_price_excluding_vat_euros > 0 &&
              <>
                <Trans>Billed annually as</Trans>&nbsp;
                  <CurrencyValue
                  value={product.annual_subscription_price_excluding_vat_euros}
                  enable_inline_spacing={true}
                  />
              </>
              }
              { billing_frequency != 'annually' || product.annual_subscription_price_excluding_vat_euros == 0 &&
                <Separator variant="h24" />
              }
            </div>

          <Separator variant="h20" />
      
    </div>

    <CheckboxButton
        isSelected={is_selected}
        unSelectedSuffix={product.name}
        />
      </div>

      <Separator variant="h20" />
      <Separator variant="h20" with_top_border={true} />

      <div css={description_style}>
        <HtmlText value={product.customer_facing_description} />
      </div>
    </div>
  )
}

const card_container_style = css`
  min-width: 280px;
  border: 2px solid ${theme.colors.nearly_white};
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 40px;
  padding-top: 40px;
  background-color: ${theme.colors.nearly_white};
  text-align: left;
  position: relative;
  border-radius: 20px;

  @media (max-width: 480px) {
    width: 100%;
    min-width: 100%;
  }
`

const product_clickable_style = css`
  cursor: pointer;
`

const card_container_selected_style = css`
  border: 2px solid ${theme.colors.primary_orange};
`

const check_container_style = css`
  width: 100%;
`

const check_common_style = css`
  padding: 5px;
  border-radius: 3px;
  width: 100%;
  text-align: center;
`

const unchecked_style = css`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.primary_blue};
  color: ${theme.colors.primary_blue};
`

const checked_style = css`
  background-color: ${theme.colors.primary_orange};
  border: 1px solid ${theme.colors.primary_orange};
  color: ${theme.colors.white};
`

const header_style = css`
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 10px;
  font-weight: 500;
`

const price_style = css``

const price_sub_style = css`
  font-size: 14px;
  display: flex;
  justify-content: left;
`

const annual_equivalent_price_style = css`
  font-size: 14px;
  margin-bottom: 20px;
  display: flex;
  justify-content: left;
`

const description_style = css``

const price_line_style = css`
  display: flex;
  justify-content: left;
  align-items: baseline;
  height: 55px;
`
